import type {LinksFunction} from '@remix-run/cloudflare';
import cn from 'classnames';

import Image from '@/components/base/elements/Image/Image';
import Typography from '@/components/base/elements/Typography/Typography';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Section from '@/components/base/layouts/Section/Section';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Card from '@/components/base/modules/Card/Card';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Link from '@/components/base/elements/Link/Link';
import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Testimonials from '@/components/base/sections/Testimonial/Testimonials';
import Conversion from '@/components/plus/redesign/section/Conversion/Conversion';
import PageLayoutRedesign from '@/components/plus/redesign/page/PageLayout';
import SideBySideList from '@/components/plus/redesign/sections/SideBySideList/SideBySideList';
import {handle as parentHandle} from '@/components/plus/shared/handle';
import {useSiteData} from '@/hooks/useSiteData';
import {useTranslations} from '@/hooks/useTranslations';
import {
  getTestimonials,
  type LogoDefType,
  lookupMerchantLogo,
} from '@/utils/merchants';
import {ColorSchemeEnum} from '@/enums';
import HeroFullBleed from '@/components/plus/redesign/section/Hero/HeroFullBleed';
import CardStats from '@/components/plus/redesign/section/CardStats/CardStats';
import SideBySideImageList from '@/components/plus/redesign/section/SideBySideImageList/SideBySideImageList';
import Globe from '@/components/plus/redesign/section/Globe/Globe';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {number, currency} from '@/utils/i18n';
import {setTestimonialsImageClass} from '@/pages/shopify.com/($locale)/plus/utils/merchants';
import type {HydratedT} from '@/components/shared/Page/I18N';
import type {Handle} from '@/types';
import brochureV2Stylesheet from '@/stylesheets/brochureV2/brochureV2.css?url';
import plusRedesignStylesheet from '@/stylesheets/plus/redesign/plus.css?url';

export {combinedLeadAction as action} from '@/utils/server/actions/plus/combinedLeadAction.server';
export {loader} from '@/utils/server/loader/loader';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: brochureV2Stylesheet,
  },
  {
    rel: 'stylesheet',
    href: plusRedesignStylesheet,
  },
];

export const handle: Handle = {
  ...parentHandle,
};

const transformStats = (t: HydratedT, prefix: string, settings: HydratedT) =>
  Object.values<{label: string}>(t(prefix)).map(({label}, idx) => ({
    value: number(t, `${prefix}.${idx}`),
    label,
    image: settings(`${prefix}.${idx}`),
  }));

const transformGlobeStats = (
  t: HydratedT,
  settings: HydratedT,
  context: string,
) => {
  const prefix = 'globe.statsCards';
  return [
    {
      value: number(t, `${prefix}.0`, {maximumFractionDigits: 2}),
      label: t(`${prefix}.0.label`, {
        uptimeAnchor: settings(`${prefix}.uptimeAnchor`, {context}),
      }),
    },
    {value: number(t, `${prefix}.1`), label: t(`${prefix}.1.label`)},
    {
      value: currency(t, `${prefix}.2`, {currencyDisplay: 'symbol'}),
      label: t(`${prefix}.2.label`),
    },
  ];
};

const isAudiencesAvailable = (locale: string) => {
  return ['en', 'es', 'en-CA', 'en-FR'].includes(locale);
};

export default function PlusHome() {
  const {t, settings} = useTranslations();
  const {site} = useSiteData();

  const logoDefs = settings('logos', {context: site.locale});
  const logos = logoDefs.map((logoDef: LogoDefType) =>
    lookupMerchantLogo(t, logoDef),
  );

  const testimonialDefs = settings('testimonials', {context: site.locale});
  const testimonials = getTestimonials(t, testimonialDefs);

  const connectCards = t('connectEverything.cards');
  const expansionCards = t('expansion.cards');

  const sharedCtaButtons = [
    {
      text: t('plus:cta.contact'),
      href: '#contact-sales',
      componentName: 'contact-sales',
    },
  ];

  const heroImages = Object.keys(
    settings('hero.images', {context: site.locale}),
  ).map((key) => ({
    alt: t(`hero.images.${key}.alt`),
    src:
      settings(`hero.images.${key}.srcSet`, {context: site.locale})?.split(
        ' ',
      )?.[0] ?? '',
    srcSet: settings(`hero.images.${key}.srcSet`, {context: site.locale}),
    lottieName: settings(`hero.images.${key}.lottieName`, {
      context: site.locale,
    }),
    animated: settings(`hero.images.${key}.animated`, {context: site.locale}),
    width: settings(`hero.images.${key}.width`, {context: site.locale}),
    height: settings(`hero.images.${key}.height`, {context: site.locale}),
  }));

  const isMobile = useMediaQuery('(max-width: 750px)');

  const statsCards = transformStats(t, 'costOperations.cardMetrics', settings);
  const localizedStatsCards = isAudiencesAvailable(site.locale)
    ? statsCards
    : statsCards.slice(1);

  return (
    <PageLayoutRedesign>
      <HeroFullBleed
        headingLines={t('hero.heading')}
        subheadHtml={t('hero.text')}
        colorScheme={ColorSchemeEnum.Black}
        mode="dark"
        images={heroImages}
        ctas={{buttons: sharedCtaButtons}}
      />
      <Section className="bg-shade-10 pb-0">
        <SectionHeader
          kicker={t('connectEverything.eyebrow')}
          headingHtml={t('connectEverything.heading')}
          subheadHtml={t('connectEverything.text')}
        />
        <CardGrid numberOfColumns={3} withContainer extraVerticalSpacing>
          {connectCards.map((card: any, index: number) => (
            <Card
              key={index}
              headingGroup={card.headingGroup}
              image={{
                alt: t(`connectEverything.cards.${index}.image.alt`),
                src: settings(`connectEverything.cards.${index}.image.src`),
                srcSet: settings(
                  `connectEverything.cards.${index}.image.srcSet`,
                ),
              }}
              size="small"
            />
          ))}
        </CardGrid>
        <LogoGroup logos={logos} className="border-b-0" />
      </Section>
      <SideBySideList
        mode="light"
        sectionHeader={t('platformExtensibility.sectionHeader')}
        gutter="left"
        sideBySideItems={t('platformExtensibility.list').map((item: any) => ({
          headingGroup: {
            ...item.headingGroup,
            headingAs: 'h3',
          },
        }))}
        video={{
          ...settings('platformExtensibility.video'),
          image: {
            ...settings('platformExtensibility.video.image'),
            alt: t('platformExtensibility.video.alt'),
          },
          videoAltDescription: t('platformExtensibility.video.alt'),
          classNames: {wrapper: 'rounded-2xl overflow-hidden h-auto'},
        }}
      />
      <Section
        mode="dark"
        topSpacing="4xl"
        bottomSpacing="4xl"
        className="relative bg-black"
      >
        <div className="z-0 absolute w-full h-full bg-[radial-gradient(76.43%_237.18%_at_94.55%_126.63%,_rgba(37,93,238,0.5)_0%,_rgba(0,0,0,0)_100%)]" />
        <div className="z-10">
          <SectionHeader
            mode="dark"
            headingHtml={t('midConversion.heading')}
            center
            className="md:pb-16 text-center"
          />
          <div className="container flex flex-col md:flex-row">
            <div className="md:w-[50%] lg:pr-20 md:pr-6 md:py-12 xs:py-10 flex flex-col items-center xs:border-b-2  xs:border-white/20 md:border-b-0 md:border-r-2 md:border-white/20">
              <HeadingGroup
                mode="dark"
                kicker={t('midConversion.shopifySection.eyebrow')}
                headingHtml={t('midConversion.shopifySection.heading')}
                size="t3"
                headingAs="h3"
                subheadHtml={t('midConversion.shopifySection.text')}
                className="w-full pb-6 text-center z-10"
                center={true}
              />
              <ButtonGroup
                mode="dark"
                center
                buttons={[
                  {
                    href: '#contact-sales',
                    text: t('midConversion.shopifySection.buttonLabel'),
                  },
                ]}
              />
            </div>
            <div className="md:w-[50%] lg:pl-20 md:pl-6 md:py-12 xs:pt-10 flex flex-col items-center">
              <HeadingGroup
                mode="dark"
                kicker={t('midConversion.commerceSection.eyebrow')}
                headingHtml={t('midConversion.commerceSection.heading')}
                size="t3"
                headingAs="h3"
                subheadHtml={t('midConversion.commerceSection.text')}
                className="w-full pb-6 text-center z-10"
                center={true}
              />
              <ButtonGroup
                mode="dark"
                center
                buttons={[
                  {
                    href: settings('midConversion.commerceComponents'),
                    intent: 'secondary',
                    text: t('midConversion.commerceSection.buttonLabel'),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section className="bg-shade-10">
        <SectionHeader
          kicker={t('expansion.eyebrow')}
          headingHtml={t('expansion.heading')}
          subheadHtml={t('expansion.text')}
        />
        <div className="container">
          <Image
            alt={t('expansion.image.alt')}
            className="w-full"
            src={
              settings('expansion.image.srcSet', {context: site.locale})?.split(
                ' ',
              )?.[0] ?? ''
            }
            srcSet={settings('expansion.image.srcSet', {context: site.locale})}
          />
          <CardGrid numberOfColumns={4} className="mt-10">
            {expansionCards.map((card: any, index: number) => (
              <Card
                key={index}
                className="border-shade-20 border-t-2 pt-6 rounded-none"
                headingGroup={card.headingGroup}
                link={
                  settings(`expansion.cards.${index}.link.href`) && {
                    text: t(`expansion.cards.${index}.link.text`),
                    href: settings(`expansion.cards.${index}.link.href`),
                  }
                }
                size="small"
              />
            ))}
          </CardGrid>
        </div>
      </Section>
      <Section className="pb-2xl">
        <SideBySideImageList
          sectionHeader={t('costOperations.sectionHeader')}
          cardItems={t('costOperations.cardItems').map(
            (item: any, index: number) => ({
              headingGroup: {
                ...item.headingGroup,
                headingAs: 'h3',
              },
              image: {
                src: settings(`costOperations.cardItemsImages.${index}`),
              },
            }),
          )}
        />
      </Section>
      <Section
        className={cn('pt-2xl', {
          'p-2xl': site.locale === 'en',
        })}
      >
        <CardStats stats={localizedStatsCards} ratio={0.15} />
      </Section>
      {site.locale === 'en' && (
        <Section className="p-2xl">
          <div className="container flex flex-col md:flex-row md:items-center gap-4 md:gap-40 lg:gap-80 p-6 md:px-10 rounded-2xl bg-[linear-gradient(91deg,#19191C_-42.64%,#13285E_96.91%)] shadow-[0px_0px_2px_0px_rgba(24,24,27,0.25),_0px_4px_8px_0px_rgba(24,24,27,0.04),_0px_8px_48px_0px_rgba(24,24,27,0.08)]">
            <HeadingGroup mode="dark" size="t4" {...t('tco.headingGroup')} />
            <ButtonGroup
              className="shrink-0"
              mode="dark"
              buttons={[
                {
                  intent: 'secondary',
                  ...t('tco.cta'),
                },
              ]}
            />
          </div>
        </Section>
      )}
      <Section className="bg-[#F9FAFB]">
        <Testimonials
          testimonials={setTestimonialsImageClass(testimonials, 'rounded-2xl')}
        />
      </Section>
      <Section
        className="relative overflow-hidden"
        bottomSpacing="2xl"
        mode="dark"
      >
        <div className="z-0 absolute w-full h-full bg-[radial-gradient(260.24%_100.24%_at_45%_125%,_rgba(37,93,238,0.50)_0%,_rgba(0,0,0,0)_100%)]" />
        {/* position center of 3299px background image to left side of our container */}
        <div className="absolute w-full h-full bg-no-repeat bg-[calc(var(--margin)-3299px/2)_20%] lg:bg-[calc(var(--margin)-3299px/2)_41%] xl:bg-[calc((100vw-1600px)/2-3299px/2)_41%] bg-[url(https://cdn.shopify.com/b/shopify-brochure2-assets/d9ce3952dba39e94d052cfb0efeeae93.svg)] pointer-events-none" />
        <SectionHeader
          mode="dark"
          kicker={t('focusedOnCommerce.eyebrow')}
          headingHtml={t('focusedOnCommerce.heading')}
        />
        <CardGrid
          numberOfColumns={3}
          withContainer
          extraVerticalSpacing
          className="z-20"
        >
          {t('focusedOnCommerce.cards').map((card: any, index: number) => (
            <Card
              mode="dark"
              key={index}
              headingGroup={{
                ...card.headingGroup,
                headingAs: 'h3',
              }}
              size="small"
              image={{
                src: settings(`focusedOnCommerce.cards.${index}.image`),
              }}
            />
          ))}
        </CardGrid>
        <Globe statsCards={transformGlobeStats(t, settings, site.locale)} />
        <div className="relative container overflow-hidden z-10 rounded-xl border border-white/20 flex flex-col md:flex-row md:justify-between px-4 md:px-8 py-8">
          <div className="absolute top-0 left-0 h-full w-full bg-[linear-gradient(93deg,_rgba(9,18,43,0)_-69.6%,_rgba(255,255,255,0.08)_113.43%),_linear-gradient(94deg,_rgba(9,18,43,0.40)_-39.46%,_rgba(9,18,45,0.20)_118.32%)]"></div>
          <div className="z-20">
            <Typography size={isMobile ? 't2' : 't4'} as="h2" className="mb-6">
              {t('customerReviews.heading')}
            </Typography>
            <Link
              mode="dark"
              className="block mb-6 md:mb-0"
              size="small"
              href={settings('customerReviews.url')}
            >
              {t('customerReviews.linkText')}
            </Link>
          </div>
          <div className="z-20">
            <Link href={settings('customerReviews.url')} arrow={false}>
              <Image
                src={settings('customerReviews.image', {context: site.locale})}
                alt={t('customerReviews.image.alt')}
                loading="lazy"
              />
            </Link>
          </div>
        </div>
      </Section>
      <Section className="relative bg-black" sectionName="conversion">
        <div className="z-0 absolute w-full h-full bg-[radial-gradient(76.43%_237.18%_at_94.55%_126.63%,_rgba(37,93,238,0.5)_0%,_rgba(0,0,0,0)_100%)]" />
        <Conversion
          className="z-10"
          mode="dark"
          headingHtml={t('conversion.headingHtml')}
          headingSize="t1"
          buttons={sharedCtaButtons}
        />
      </Section>
    </PageLayoutRedesign>
  );
}
