import Section from '@/components/base/layouts/Section/Section';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Typography from '@/components/base/elements/Typography/Typography';
import type {HeroProps} from '@/components/plus/redesign/section/Hero/types';
import useCtasOrSignup from '@/hooks/useCtasOrSignup/useCtasOrSignup';
import Collage from '@/components/plus/redesign/section/Collage/Collage';
import {usePlusNavLimitPages} from '@/hooks/experiments/usePlusNavLimitPages';

export default function HeroFullBleed({
  className,
  colorScheme,
  ctas,
  headingHtml,
  headingLines,
  signup,
  subheadHtml,
  mode = 'dark',
  images,
  ...props
}: HeroProps) {
  const buttonGroupProps = useCtasOrSignup(signup || {}, ctas);
  const {cs} = usePlusNavLimitPages();

  return (
    <Section
      className="bg-black pb-0 pt-[calc(var(--space-3xl)+var(--header-height)+1px)] gap-y-3xl relative mt-[calc((var(--header-height)+1px)*-1)] overflow-hidden"
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      {...props}
    >
      <div className="absolute top-0 w-full h-full overflow-hidden bg-[#070D20]">
        <div className="motion-safe:md:will-change-transform motion-safe:md:animate-plus-hero-gradient-1 w-[1518px] h-[1342px] top-0 left-[50%] translate-x-[-20%] translate-y-[0px] rotate-[-30deg] origin-center absolute rounded-full bg-[rgba(37,93,238,0.2)] mix-blend-normal blur-[204px]" />
        <div className="motion-safe:md:will-change-transform motion-safe:md:animate-plus-hero-gradient-2 w-[1060px] h-[1060px] top-1/2 left-[70%] translate-x-[-75%] translate-y-[-75%] scale-x-[0.65] rotate-[80deg] origin-center absolute rounded-full bg-gradient-to-b from-[rgba(37,93,238,0.2)] to-black mix-blend-normal blur-[204px] delay-300" />
        <div className="motion-safe:md:will-change-transform motion-safe:md:animate-plus-hero-gradient-3 w-[1242px] h-[1242px] right-[-10%] translate-x-[-10%] translate-y-[-25%] scale-y-[65%] rotate-[-50deg] origin-center absolute rounded-full bg-[rgba(37,93,238,0.1)] mix-blend-normal blur-[228px] delay-600" />
        <div className="absolute bottom-0 w-full h-[400px] bg-gradient-to-t from-black to-transparent" />
      </div>
      <div className="absolute bottom-0 right-0 w-full h-[683px] bg-no-repeat bg-right-bottom bg-[url(https://cdn.shopify.com/b/shopify-brochure2-assets/c6cef94166136a3686030327d6390ea9.svg)]" />
      <div
        className={`container flex flex-col items-center z-10 ${cs('pt-2xl')}`}
      >
        {headingHtml && (
          <Typography
            mode={mode}
            as="h1"
            className="text-t2 md:text-t1 text-center"
          >
            {headingHtml}
          </Typography>
        )}
        {headingLines?.length && (
          <Typography
            mode={mode}
            as="h1"
            className="text-t2 md:text-t1 text-center whitespace-balance"
          >
            {headingLines.map((line) => (
              <div key={line}>{line}</div>
            ))}
          </Typography>
        )}
        {subheadHtml && (
          <Typography
            mode={mode}
            size="body-lg"
            className="text-center pt-sm md:pt-md"
          >
            {subheadHtml}
          </Typography>
        )}
        {buttonGroupProps && (
          <ButtonGroup className="pt-md" mode={mode} {...buttonGroupProps} />
        )}
      </div>
      <Collage images={images} />
      <div className="absolute bottom-0 bg-[linear-gradient(to_bottom,_transparent,_#080E20)] w-full h-[136px]" />
    </Section>
  );
}
